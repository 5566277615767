<template>
    <div class="connections scrollable no-show-scroll">
        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <HeadingComponent title="Connections" description="Add your connections here to start building projects"
            action="Create a new connection" action-modal="#newConnectionModal" action-enabled="true">
        </HeadingComponent>
        <!-- Create new connection Modal-->
        <div class="modal fade" id="newConnectionModal" tabindex="-1" role="dialog" aria-labelledby="newConnectionModal"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Create a connection</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                        </button>
                    </div>
                    <div class="modal-body">
                        <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                        <form class="user mt-5">
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-6 offset-sm-3 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.name"
                                        class="form-control form-control-user"
                                        :class="{ 'border border-danger': nameError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Name" id="name-new" placeholder="Name"
                                        autocomplete="name">
                                        <label for="name-new">Name</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="password" v-model="connectionDto.username" autocomplete="username"
                                        :class="{ 'border border-danger': usernameError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Username" class="form-control form-control-user"
                                        id="username-new" placeholder="Username">
                                        <label for="username-new">Username</label>
                                </div>
                                <div class="form-floating col-sm-6">
                                    <input type="password" v-model="connectionDto.password" autocomplete="new-password"
                                        :class="{ 'border border-danger': passwordError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Password" class="form-control form-control-user"
                                        id="password-new" placeholder="Password">
                                        <label for="password-new">Password</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.host"
                                        class="form-control form-control-user"
                                        :class="{ 'border border-danger': hostError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Host" id="host-new" placeholder="Host">
                                        <label for="host-new">Host</label>
                                </div>
                                <div class="form-floating col-sm-6">
                                    <input type="text" v-model="connectionDto.client"
                                        class="form-control form-control-user"
                                        :class="{ 'border border-danger': clientError }" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Client" id="client-new" placeholder="Client">
                                        <label for="client-new">Client</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.language" data-bs-toggle="tooltip"
                                        :class="{ 'border border-danger': languageError }" data-bs-placement="top"
                                        title="Language" class="form-control form-control-user" id="language-new"
                                        placeholder="Language">
                                        <label for="language-new">Language</label>
                                </div>
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.systemNumber" data-bs-toggle="tooltip"
                                        :class="{ 'border border-danger': systemNumberError }" data-bs-placement="top"
                                        title="System number" class="form-control form-control-user"
                                        id="systemNumber-new" placeholder="System number">
                                        <label for="systemNumber-new">System number</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.batchSize" data-bs-toggle="tooltip"
                                        :class="{ 'border border-danger': batchSizeError }" data-bs-placement="top"
                                        title="Batch size" class="form-control form-control-user" id="batchSize-new"
                                        placeholder="Batch size">
                                        <label for="batchSize-new">Batch size</label>
                                </div>
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.joinChunkSize" data-bs-toggle="tooltip"
                                        :class="{ 'border border-danger': joinChunkSizeError }" data-bs-placement="top"
                                        title="Join junk size" class="form-control form-control-user"
                                        id="JoinChunkSize-new" placeholder="Join junk size">
                                        <label for="JoinChunkSize-new">Join junk size</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-check small ms-3">
                                    <input type="checkbox" v-model="connectionDto.usesLoadBalancing"
                                        class="form-check-input" data-bs-toggle="tooltip" data-bs-placement="top"
                                        title="Load balancer" id="load-balancer-new" placeholder="Load balancer">
                                    <label class="form-check-label" style="float:left;" for="load-balancer-new">Load balancer?</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-floating col-sm-12 mb-12 mb-sm-0">
                                    <textarea type="text" class="form-control small shadow-sm" rows="8"
                                        v-model="connectionDto.description" placeholder="Connection description"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Connection description"
                                        aria-label="Update" aria-describedby="basic-addon2"
                                        id="connection-description-new" />
                                        <label for="connection-description-new">Description</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1">
                                <div class="form-check small ms-3">
                                    <input type="checkbox" v-model="connectionDto.sncEnabled" class="form-check-input"
                                        data-bs-toggle="tooltip" data-bs-placement="top" title="Enable SNC" id="snc-new"
                                        placeholder="Enable SNC">
                                    <label class="form-check-label" style="float:left;" for="snc-new">Enable SNC</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1" v-if="connectionDto.sncEnabled">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.externalIdData" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="External id data"
                                        class="form-control form-control-user" id="externalIdData-new"
                                        placeholder="External id data">
                                        <label for="externalIdData-new">External id data</label>
                                </div>
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.libraryPath" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Library path"
                                        class="form-control form-control-user" id="libraryPath-new"
                                        placeholder="Library path">
                                        <label for="libraryPath-new">Library path</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1" v-if="connectionDto.sncEnabled">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.ownName" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Own name" class="form-control form-control-user"
                                        id="ownName-new" placeholder="Own name">
                                        <label for="ownName-new">Own name</label>
                                </div>
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <input type="text" v-model="connectionDto.partnerName" data-bs-toggle="tooltip"
                                        data-bs-placement="top" title="Partner name"
                                        class="form-control form-control-user" id="partnerName-new"
                                        placeholder="Partner name">
                                        <label for="partnerName-new">Partner name</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1" v-if="connectionDto.sncEnabled">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <select v-model="connectionDto.qualityOfProtection"
                                        class="form-select form-select-sm" data-bs-toggle="tooltip"
                                        style="min-width: 220px;" data-bs-placement="top" title="Quality protection"
                                        id="quality-protection" placeholder="Quality protection">
                                        <option value="0">Undefined</option>
                                        <option value="1">Authentication</option>
                                        <option value="2">Integrity</option>
                                        <option value="3">Privacy</option>
                                        <option value="8">Default</option>
                                        <option value="9">Maximum</option>
                                    </select>
                                    <label for="quality-protection">Quality protection</label>
                                </div>
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <select v-model="connectionDto.externalIdType" class="form-select form-select-sm"
                                        data-bs-toggle="tooltip" style="min-width: 220px;" data-bs-placement="top"
                                        title="External id type" id="ExternalIdType" placeholder="External id type">
                                        <option value="0">Unspecified</option>
                                        <option value="1">Email</option>
                                        <option value="2">Kerberos</option>
                                        <option value="3">Transient</option>
                                        <option value="4">WindowsName</option>
                                        <option value="5">X509SubjectName</option>
                                        <option value="6">SapUserName</option>
                                    </select>
                                    <label for="ExternalIdType">External id type</label>
                                </div>
                            </div>
                            <div class="mb-3 row g-1" v-if="connectionDto.sncEnabled">
                                <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                    <select v-model="connectionDto.sNCMechanism" class="form-select form-select-sm"
                                        data-bs-toggle="tooltip" style="min-width: 220px;" data-bs-placement="top"
                                        title="SNC mechanism" id="sNCMechanism" placeholder="SNC mechanism">
                                        <option value="0">NTLM</option>
                                        <option value="1">Kerberos5</option>
                                    </select>
                                    <label for="sNCMechanism">SNC mechanism</label>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                            Cancel
                        </button>
                        <a class="btn btn-primary" @click="createConnection()" data-bs-dismiss="modal">Save</a>
                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-success ms-2"
                            v-if="connectionEstablished != null && connectionEstablished == true">
                            Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                        </a>
                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-danger ms-2"
                            v-else-if="connectionEstablished != null && connectionEstablished != true">
                            Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                        </a>
                        <a href="#" v-on:click="testConnectionDto()" class="btn btn-outline-primary ms-2" v-else>
                            Test
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ContentWrapper>
            <SearchBarComponent title="All connections"></SearchBarComponent>
            <div class="row me-5 mt-4">
                <div class="col-xl-4 col-md-6 mb-3" v-for="connection in filteredConnections" :key="connection.id">
                    <div class="card shadow-sm" style="min-height:240px;">
                        <div class="card-header">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <h6 class="m-0 fw-bold text-gray-600">
                                    <img src="../assets/images/sap.svg" /> {{ connection.name }}
                                </h6>
                                <DropdownComponent>
                                    <a class="dropdown-item text-info" href="#"
                                        v-on:click="testConnection(connection.id)">
                                        <i class="fa fa-regular fa-copy"></i>
                                        Test connection
                                    </a>
                                    <a class="dropdown-item text-warning" href="#" data-bs-dismiss="modal"
                                        data-bs-toggle="modal" data-bs-target="#editConnectionWizardModal"
                                        v-on:click="selectEditConnection(connection.id)">
                                        <i class="fa fa-duotone fa-pen-to-square"></i>
                                        Edit connection
                                    </a>
                                    <a class="dropdown-item text-danger" href="#" data-bs-dismiss="modal"
                                        v-on:click="selectConnection(connection.id)" data-bs-toggle="modal"
                                        data-bs-target="#removeConnectionModal">
                                        <i class="fa-solid fa-trash-can"></i>
                                        Remove connection
                                    </a>
                                </DropdownComponent>
                            </div>
                        </div>

                        <div class="card-body">
                            <h1 class="h6">{{ connection.description }}</h1>
                        </div>
                        <div class="card-footer">
                            <div class="d-sm-flex align-items-center justify-content-between">
                                <h6 class="m-0 fw-bold text-gray-600" v-if="connection.connectionEstablished">
                                    <span class="text-success">Connection established</span>
                                </h6>
                                <h6 class="m-0 fw-bold text-warning" v-else>
                                    Connection not established
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="removeConnectionModal" tabindex="-1" role="dialog"
                aria-labelledby="removeConnectionModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h6>Are you sure you want to remove connection?</h6>
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                            </button>
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <a class="btn btn-primary" data-bs-dismiss="modal"
                                v-on:click="removeConnection()">Remove</a>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit connection-->
            <div class="modal fade" id="editConnectionWizardModal" tabindex="-1" role="dialog"
                aria-labelledby="editConnectionWizardModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <img src="../assets/images/sap.svg" />
                            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close">

                            </button>
                        </div>
                        <div class="modal-body">
                            <h5 class="modal-title" id="exampleModalLabel">Setup connection</h5>
                            <h5 class="h6 text-danger">Username and password are not shown. Only edit if change is
                                necessary.</h5>
                            <form class="user mt-5">
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-6 offset-sm-3 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.name"
                                            :class="{ 'border border-danger': nameError }"
                                            class="form-control form-control-user" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Name" id="name-edit" placeholder="Name"
                                            autocomplete="name">
                                        <label for="name-edit">Name</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="password" v-model="selectedConnection.username"
                                            autocomplete="username" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Username" class="form-control form-control-user" id="username-edit"
                                            placeholder="Username">
                                            <label for="username-edit">Username</label>                                            
                                    </div>
                                    <div class="form-floating col-sm-6">
                                        <input type="password" v-model="selectedConnection.password"
                                            autocomplete="new-password" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Password" class="form-control form-control-user" id="password-edit"
                                            placeholder="Password">
                                            <label for="password-edit">Password</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.host"
                                            :class="{ 'border border-danger': hostError }"
                                            class="form-control form-control-user" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Host" id="host-edit" placeholder="Host">
                                            <label for="host-edit">Host</label>
                                    </div>
                                    <div class="form-floating col-sm-6">
                                        <input type="text" v-model="selectedConnection.client"
                                            :class="{ 'border border-danger': clientError }"
                                            class="form-control form-control-user" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Client" id="client-edit"
                                            placeholder="Client">
                                            <label for="client-edit">Client</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.language"
                                            data-bs-toggle="tooltip" :class="{ 'border border-danger': languageError }"
                                            data-bs-placement="top" title="Language"
                                            class="form-control form-control-user" id="language-edit"
                                            placeholder="Language">
                                            <label for="language-edit">Language</label>
                                    </div>
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.systemNumber"
                                            data-bs-toggle="tooltip"
                                            :class="{ 'border border-danger': systemNumberError }"
                                            data-bs-placement="top" title="System number"
                                            class="form-control form-control-user" id="systemNumber-edit"
                                            placeholder="System number">
                                            <label for="systemNumber-edit">System number</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.batchSize"
                                            data-bs-toggle="tooltip" :class="{ 'border border-danger': batchSizeError }"
                                            data-bs-placement="top" title="Batch size"
                                            class="form-control form-control-user" id="batchSize-edit"
                                            placeholder="Batch size">
                                            <label for="batchSize-edit">Batch size</label>
                                    </div>
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.joinChunkSize"
                                            data-bs-toggle="tooltip"
                                            :class="{ 'border border-danger': joinChunkSizeError }"
                                            data-bs-placement="top" title="Join junk size"
                                            class="form-control form-control-user" id="JoinChunkSize-edit"
                                            placeholder="Join junk size">
                                            <label for="JoinChunkSize-edit">Join chunk size</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-check small ms-3">
                                        <input type="checkbox" v-model="selectedConnection.usesLoadBalancing"
                                            class="form-check-input" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Load balancer" id="load-balancer-edit" placeholder="Load balancer">
                                        <label class="form-check-label" style="float:left;" for="load-balancer-edit">Load balancer?</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-floating col-sm-12 mb-12 mb-sm-0">
                                        <textarea type="text" class="form-control small shadow-sm" rows="8"
                                            v-model="selectedConnection.description"
                                            placeholder="Connection description" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Connection description" aria-label="Update"
                                            aria-describedby="basic-addon2" id="connection-description-edit" />
                                            <label class="form-check-label" for="connection-description-edit">Description</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1">
                                    <div class="form-check small ms-3">
                                        <input type="checkbox" v-model="selectedConnection.sncEnabled"
                                            class="form-check-input" data-bs-toggle="tooltip" data-bs-placement="top"
                                            title="Enable SNC" id="snc-edit" placeholder="Enable SNC">
                                        <label class="form-check-label" style="float:left;" for="snc-edit">Enable SNC</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1" v-if="selectedConnection.sncEnabled">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.externalIdData"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="External id data"
                                            class="form-control form-control-user" id="externalIdData-edit"
                                            placeholder="External id data">
                                            <label class="form-check-label" for="externalIdData-edit">External ID</label>
                                    </div>
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.libraryPath"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Library path"
                                            class="form-control form-control-user" id="libraryPath-edit"
                                            placeholder="Library path">
                                            <label class="form-check-label" for="libraryPath-edit">Library path</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1" v-if="selectedConnection.sncEnabled">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.ownName" data-bs-toggle="tooltip"
                                            data-bs-placement="top" title="Own name"
                                            class="form-control form-control-user" id="ownName-edit"
                                            placeholder="Own name">
                                            <label class="form-check-label" for="ownName-edit">Own name</label>                                            
                                    </div>
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <input type="text" v-model="selectedConnection.partnerName"
                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Partner name"
                                            class="form-control form-control-user" id="partnerName-edit"
                                            placeholder="Partner name">
                                            <label class="form-check-label" for="partnerName-edit">Partner name</label>                                            
                                    </div>
                                </div>
                                <div class="mb-3 row g-1" v-if="selectedConnection.sncEnabled">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <select v-model="selectedConnection.qualityOfProtection"
                                            class="form-select form-select-sm" data-bs-toggle="tooltip"
                                            style="min-width: 220px;" data-bs-placement="top" title="Quality protection"
                                            id="quality-protection-edit" placeholder="Quality protection">
                                            <option value="0">Undefined</option>
                                            <option value="1">Authentication</option>
                                            <option value="2">Integrity</option>
                                            <option value="3">Privacy</option>
                                            <option value="8">Default</option>
                                            <option value="9">Maximum</option>
                                        </select>
                                        <label class="form-check-label" for="quality-protection-edit">Quality protection</label>                                            
                                    </div>
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <select v-model="selectedConnection.externalIdType"
                                            class="form-select form-select-sm" data-bs-toggle="tooltip"
                                            style="min-width: 220px;" data-bs-placement="top" title="External id type"
                                            id="ExternalIdType-edit" placeholder="External id type">
                                            <option value="0">Unspecified</option>
                                            <option value="1">Email</option>
                                            <option value="2">Kerberos</option>
                                            <option value="3">Transient</option>
                                            <option value="4">WindowsName</option>
                                            <option value="5">X509SubjectName</option>
                                            <option value="6">SapUserName</option>
                                        </select>
                                        <label class="form-check-label" for="ExternalIdType-edit">External id type</label>
                                    </div>
                                </div>
                                <div class="mb-3 row g-1" v-if="selectedConnection.sncEnabled">
                                    <div class="form-floating col-sm-6 mb-3 mb-sm-0">
                                        <select v-model="selectedConnection.sncMechanism"
                                            class="form-select form-select-sm" data-bs-toggle="tooltip"
                                            style="min-width: 220px;" data-bs-placement="top" title="SNC mechanism"
                                            id="sNCMechanism-edit" placeholder="SNC mechanism">
                                            <option value="0">NTLM</option>
                                            <option value="1">Kerberos5</option>
                                        </select>
                                        <label class="form-check-label" for="sNCMechanism-edit">SNC mechanism</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <div class="row">
                                <div class="col-md-12 mb-3 mb-sm-0">
                                    <a href="#" class="btn btn-primary" data-bs-dismiss="modal"
                                        v-on:click="updateConnection()">
                                        Save
                                    </a>
                                    <a href="#" v-on:click="testConnection(selectedConnection.id)"
                                        class="btn btn-outline-success ms-2"
                                        v-if="connectionEstablished != null && connectionEstablished == true">
                                        Test <i class="fa-sharp fa-solid fa-circle-check text-success"></i>
                                    </a>
                                    <a href="#" v-on:click="testConnection(selectedConnection.id)"
                                        class="btn btn-outline-danger ms-2"
                                        v-else-if="connectionEstablished != null && connectionEstablished != true">
                                        Test <i class="fa-sharp fa-solid fa-circle-check text-danger"></i>
                                    </a>
                                    <a href="#" v-on:click="testConnection(selectedConnection.id)"
                                        class="btn btn-outline-primary ms-2" v-else>
                                        Test
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    </div>
</template>

<script>
// @ is an alias to /src
import ConnectionsService from "../services/connectionsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import HeadingComponent from '../components/HeadingComponent.vue'
import SearchBarComponent from '../components/SearchBarComponent.vue'
import ContentWrapper from '../components/ContentWrapper.vue'
import DropdownComponent from '../components/DropdownComponent.vue'

export default {
    name: "ConnectionsView",
    components: { LoadingIndicator, HeadingComponent, SearchBarComponent, ContentWrapper, DropdownComponent },
    data: () => {
        return {
            connections: [],
            searchTerm: null,
            filteredConnections: [],
            loading: true,
            orderBy: false,
            selectedConnectionId: null,
            connectionDto: {
                name: null,
                description: null,
                client: null,
                username: null,
                password: null,
                host: null,
                systemNumber: null,
                language: null,
                usesLoadBalancing: false,
                sncEnabled: false,
                sncMechanism: null,
                qualityOfProtection: null,
                externalIdData: null,
                externalIdType: null,
                libraryPath: null,
                ownName: null,
                partnerName: null,
                joinChunkSize: 1000,
                batchSize: 100000
            },
            selectedConnection: {
                id: null,
                name: null,
                description: null,
                client: null,
                username: null,
                password: null,
                host: null,
                systemNumber: null,
                language: null,
                usesLoadBalancing: false,
                sncEnabled: false,
                sncMechanism: null,
                qualityOfProtection: null,
                externalIdData: null,
                externalIdType: null,
                libraryPath: null,
                ownName: null,
                partnerName: null,
                joinChunkSize: 100,
                batchSize: 10000
            },
            connectionEstablished: null,
            sortText: '<i class="fa-sharp fa-solid fa-arrow-up"></i>',
            nameError: false,
            descriptionError: false,
            clientError: false,
            usernameError: false,
            passwordError: false,
            hostError: false,
            systemNumberError: false,
            languageError: false,
            joinChunkSizeError: false,
            batchSizeError: false
        };
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredConnections = this.connections.filter(x => x.name.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1 || x.description?.toLowerCase()?.indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredConnections = this.connections
            }
        },
        'connectionDto.name': function (newTerm) {
            if (!newTerm) {
                this.nameError = true;
            } else {
                this.nameError = false;
            }
        },
        'connectionDto.client': function (newTerm) {
            if (!newTerm) {
                this.clientError = true;
            } else {
                this.clientError = false;
            }
        },
        'connectionDto.username': function (newTerm) {
            if (!newTerm) {
                this.usernameError = true;
            } else {
                this.usernameError = false;
            }
        },
        'connectionDto.password': function (newTerm) {
            if (!newTerm) {
                this.passwordError = true;
            } else {
                this.passwordError = false;
            }
        },
        'connectionDto.systemNumber': function (newTerm) {
            if (!newTerm) {
                this.systemNumberError = true;
            } else {
                this.systemNumberError = false;
            }
        },
        'connectionDto.language': function (newTerm) {
            if (!newTerm) {
                this.languageError = true;
            } else {
                this.languageError = false;
            }
        },
        'connectionDto.batchSize': function (newTerm) {
            if (!newTerm) {
                this.batchSizeError = true;
            } else {
                this.batchSizeError = false;
            }
        },
        'connectionDto.joinChunkSize': function (newTerm) {
            if (!newTerm) {
                this.joinChunkSizeError = true;
            } else {
                this.joinChunkSizeError = false;
            }
        },
        'connectionDto.host': function (newTerm) {
            if (!newTerm) {
                this.hostError = true;
            } else {
                this.hostError = false;
            }
        },
        'selectedConnection.name': function (newTerm) {
            if (!newTerm) {
                this.nameError = true;
            } else {
                this.nameError = false;
            }
        },
        'selectedConnection.client': function (newTerm) {
            if (!newTerm) {
                this.clientError = true;
            } else {
                this.clientError = false;
            }
        },
        'selectedConnection.username': function (newTerm) {
            if (!newTerm) {
                this.usernameError = true;
            } else {
                this.usernameError = false;
            }
        },
        'selectedConnection.password': function (newTerm) {
            if (!newTerm) {
                this.passwordError = true;
            } else {
                this.passwordError = false;
            }
        },
        'selectedConnection.systemNumber': function (newTerm) {
            if (!newTerm) {
                this.systemNumberError = true;
            } else {
                this.systemNumberError = false;
            }
        },
        'selectedConnection.language': function (newTerm) {
            if (!newTerm) {
                this.languageError = true;
            } else {
                this.languageError = false;
            }
        },
        'selectedConnection.batchSize': function (newTerm) {
            if (!newTerm) {
                this.batchSizeError = true;
            } else {
                this.batchSizeError = false;
            }
        },
        'selectedConnection.joinChunkSize': function (newTerm) {
            if (!newTerm) {
                this.joinChunkSizeError = true;
            } else {
                this.joinChunkSizeError = false;
            }
        },
        'selectedConnection.host': function (newTerm) {
            if (!newTerm) {
                this.hostError = true;
            } else {
                this.hostError = false;
            }
        },
    },
    created() {
        this.getConnections();
    },
    methods: {
        getConnections() {
            ConnectionsService.getConnections(this.orderBy).then((connections) => {
                this.connections = connections;
                this.filteredConnections = connections;
                this.loading = false;
            });
        },
        sort() {
            this.orderBy = !this.orderBy;
            this.sortText = this.orderBy ? '<i class="fa-sharp fa-solid fa-arrow-down"></i>' : '<i class="fa-sharp fa-solid fa-arrow-up"></i>';
            this.getConnections();
        },
        selectConnection(id) {
            this.selectedConnectionId = id;
        },
        resetErrorValues() {
            this.nameError = false;
            this.descriptionError = false;
            this.clientError = false;
            this.usernameError = false;
            this.passwordError = false;
            this.hostError = false;
            this.systemNumberError = false;
            this.languageError = false;
            this.joinChunkSizeError = false;
            this.batchSizeError = false;
        },
        removeConnection() {
            this.loading = true;
            ConnectionsService.deleteConnection(this.selectedConnectionId).then(() => {
                this.getConnections();
            })
                .catch(error => {
                    this.loading = false;
                    const $toast = useToast();
                    if (error != null) {
                        $toast.error(error);
                    } else {
                        $toast.error('There was an error, please try again later or contact support');
                    }
                })
        },
        testConnection(id) {
            this.loading = true;
            ConnectionsService.testConnection(id)
                .then((res) => {
                    const $toast = useToast();
                    if (res) {
                        $toast.success('Connection successful!');
                    }
                    else {
                        $toast.error('Connection test failed!');
                    }
                    this.getConnections();
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        selectEditConnection(id) {
            this.selectedConnection = this.connections.find(x => x.id == id);
        },
        updateConnection() {
            this.loading = true;
            ConnectionsService.updateConnection(this.selectedConnection.id, this.selectedConnection).then(() => {
                const $toast = useToast();
                $toast.success('Update successful!');
                this.getConnections();
                this.resetErrorValues();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        createConnection() {
            this.loading = true;

            if (!this.validateCreate()) {
                this.loading = false;
                return;
            }

            ConnectionsService.createConnection(this.connectionDto).then(() => {
                const $toast = useToast();
                $toast.success('Connection created!');
                this.getConnections();
                this.resetErrorValues();
            })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        testConnectionDto() {
            ConnectionsService.testConnectionDto(this.connectionDto)
                .then((isOk) => {
                    const $toast = useToast();
                    if (isOk) {
                        $toast.success('Connection successful!');
                    }
                    else {
                        $toast.error('Connection test failed!');
                    }
                    this.connectionEstablished = isOk;
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        validateCreate() {
            let isOk = true;

            if (!this.connectionDto.name) {
                this.nameError = true;
                isOk = false;
            }

            if (!this.connectionDto.username) {
                this.usernameError = true;
                isOk = false;
            }

            if (!this.connectionDto.password) {
                this.passwordError = true;
                isOk = false;
            }

            if (!this.connectionDto.client) {
                this.clientError = true;
                isOk = false;
            }

            if (!this.connectionDto.host) {
                this.hostError = true;
                isOk = false;
            }

            if (!this.connectionDto.batchSize) {
                this.batchSizeError = true;
                isOk = false;
            }

            if (!this.connectionDto.joinChunkSize) {
                this.joinChunkSizeError = true;
                isOk = false;
            }

            if (!this.connectionDto.language) {
                this.languageError = true;
                isOk = false;
            }

            if (!this.connectionDto.systemNumber) {
                this.systemNumberError = true;
                isOk = false;
            }

            let joinChunkSize = parseInt(this.connectionDto.joinChunkSize);

            if (typeof joinChunkSize != 'number' || joinChunkSize < 1 || isNaN(joinChunkSize)) {
                const $toast = useToast();
                $toast.error('Join chunk size must be a positive number bigger then zero (0)');
            }

            let batchSize = parseInt(this.connectionDto.batchSize);

            if (typeof batchSize != 'number' || batchSize < 1 || isNaN(batchSize)) {
                const $toast = useToast();
                $toast.error('Batch size must be a positive number bigger then zero (0)');
            }

            let systemNumber = parseInt(this.connectionDto.systemNumber);

            if (typeof systemNumber != 'number' || systemNumber < 0 || isNaN(systemNumber)) {
                const $toast = useToast();
                $toast.error('System number must be a positive number');
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        },
        validateUpdate() {
            let isOk = true;

            if (!this.selectedConnection.name) {
                this.nameError = true;
                isOk = false;
            }

            if (!this.selectedConnection.username) {
                this.usernameError = true;
                isOk = false;
            }

            if (!this.selectedConnection.password) {
                this.passwordError = true;
                isOk = false;
            }

            if (!this.selectedConnection.client) {
                this.clientError = true;
                isOk = false;
            }

            if (!this.selectedConnection.host) {
                this.hostError = true;
                isOk = false;
            }

            if (!this.selectedConnection.batchSize) {
                this.batchSizeError = true;
                isOk = false;
            }

            if (!this.selectedConnection.joinChunkSize) {
                this.joinChunkSizeError = true;
                isOk = false;
            }

            if (!this.selectedConnection.language) {
                this.languageError = true;
                isOk = false;
            }

            if (!this.selectedConnection.systemNumber) {
                this.systemNumberError = true;
                isOk = false;
            }

            let joinChunkSize = parseInt(this.selectedConnection.joinChunkSize);

            if (typeof joinChunkSize != 'number' || joinChunkSize < 0 || isNaN(joinChunkSize)) {
                const $toast = useToast();
                $toast.error('Join chunk size must be a positive number');
            }

            let batchSize = parseInt(this.selectedConnection.batchSize);

            if (typeof batchSize != 'number' || batchSize < 0 || isNaN(batchSize)) {
                const $toast = useToast();
                $toast.error('Batch size must be a positive number');
            }

            let systemNumber = parseInt(this.selectedConnection.systemNumber);

            if (typeof systemNumber != 'number' || systemNumber < 0 || isNaN(systemNumber)) {
                const $toast = useToast();
                $toast.error('System number must be a positive number');
            }

            if (!isOk) {
                const $toast = useToast();
                $toast.error('Please fill in required fields');
            }

            return isOk;
        }
    }
};
</script>
