<template>
    <div class="project scrollable no-show-scroll" v-cloak>

        <LoadingIndicator v-if="loading"></LoadingIndicator>

        <div class="row hd-bg-color" v-cloak style="min-height: 100vh;">
            <div class="col-lg-6 col-md-6 offset-lg-4 offset-md-6 mx-auto mb-4 mt-4">
                <div class="card shadow-sm">
                    <div class="card-header">
                        <div class="row d-flex align-items-center">
                            <div class="form-floating col-md-12">
                                <input type="text" v-model="job.name" id="jobName"
                                    class="form-control bg-light border-0" placeholder="Job name" />
                                <label class="fw-bold" for="jobName">Name:</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card shadow-sm mt-3">
                    <div class="card-body">
                        <form-wizard step-size="xs" color="#04B187" @on-complete="finalize()">
                            <tab-content title="Connection" :beforeChange="validateConnection"
                                style="min-height: 400px;">

                                <ConnectionComponent :project=project @connectionChanged="getProject" title="Connection"
                                    description="Check your connection before adding a job" :editMode="true">
                                </ConnectionComponent>

                            </tab-content>
                            <tab-content title="Select tables" style="min-height: 400px;">
                                <div class="list-inline" v-if="tables">
                                    <div class="row">
                                        <a href="#" class="col-3 mb-2" style="border:0" v-for="table in tables"
                                            :key="table.id">
                                            <!-- <li href="#" class="list-inline-item m-2 mb-2" style="border:0"
                                            v-for="table in tables" :key="table.id">
                                            <div class="d-sm-flex align-items-center justify-content-between"
                                                :class="{ 'active-table': table.isChecked, 'inactive-table': !table.isChecked }">
                                                <span class="m-1 mb-1 fw-bold"
                                                    style="color:#a1a1a1; min-width:100px; cursor: pointer;"
                                                    v-on:click.prevent="checkTable(table.id, table.isChecked)">
                                                    {{ table.tabname }}
                                                </span>
                                                <ul class="navbar-nav ms-auto pe-2 mt-1">
                                                    <li class="nav-item dropdown no-arrow">
                                                        <a class="nav-link dropdown-toggle custom-dropdown-toggle"
                                                            href="#" id="projectDropdown" role="button"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                                        </a>
                                                        <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                                            aria-labelledby="projectDropdown">
                                                            <a href="#" class="dropdown-item text-danger"
                                                                style="color:#7B7B7B;"
                                                                v-on:click="removeTable(table.tabname)"><i
                                                                    class="fa-solid fa-trash-can"></i>
                                                                Remove </a>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li> -->
                                            <div
                                                :class="{ 'active-table': table.isChecked, 'inactive-table': !table.isChecked }">
                                                <div class="row">
                                                    <div class="col-9"
                                                        style="color:#a1a1a1; min-width:100px; cursor: pointer;"
                                                        v-on:click.prevent="checkTable(table.id, table.isChecked)">
                                                        <span class="m-1 mt-2 fw-bold">
                                                            {{ table.tabname }}
                                                        </span>
                                                    </div>
                                                    <div class="col-2">
                                                        <ul class="navbar-nav pe-2 mt-1">
                                                            <li class="nav-item dropdown no-arrow">
                                                                <a class="nav-link dropdown-toggle custom-dropdown-toggle"
                                                                    href="#" id="projectDropdown" role="button"
                                                                    data-bs-toggle="dropdown" aria-haspopup="true"
                                                                    aria-expanded="false">
                                                                    <h6><i class="fa-solid fa-ellipsis"></i></h6>
                                                                </a>
                                                                <div class="dropdown-menu dropdown-menu-start shadow animated--grow-in"
                                                                    aria-labelledby="projectDropdown">
                                                                    <a href="#" class="dropdown-item text-danger"
                                                                        style="color:#7B7B7B;"
                                                                        v-on:click="removeTable(table.tabname)"><i
                                                                            class="fa-solid fa-trash-can"></i>
                                                                        Remove </a>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="row mt-4">
                                        <form class="d-none d-sm-inline-block d-flex align-items-center me-auto">
                                            <div class="input-group">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control bg-light border-0 small"
                                                        v-model="newTable" placeholder="Add new table"
                                                        aria-label="Add new table" aria-describedby="basic-addon2" />
                                                    <label for="newTable" class="form-label">Add new table</label>
                                                </div>
                                                <div class="input-group-text">
                                                    <button class="btn btn-primary" type="button"
                                                        v-on:click.prevent="addNewTable()">
                                                        <i class="fa-solid fa-square-plus"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Select fields and priority" style="min-height: 400px;">
                                <div id="accordion">
                                    <div class="row overflow-auto" style="height: 400px;">
                                        <div class="col-md-12">
                                            <draggable tag="div" v-model="checkedTables" item-key="tabname">
                                                <template #item="{ element }">
                                                    <div class="card">
                                                        <div class="card-header" :id='"headingOne" + element.tabname'>
                                                            <h5 class="mb-0">
                                                                <div
                                                                    class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded">
                                                                    <span class="m-0 fw-bold">
                                                                        <i class="fa-solid fa-grip-vertical"></i> {{
                                                                            element.tabname
                                                                        }}
                                                                    </span>
                                                                    <button class="btn btn-sm btn-light"
                                                                        style="min-width:100px;"
                                                                        data-bs-toggle="collapse"
                                                                        v-on:click.prevent="selectTable(element.id)"
                                                                        v-if="checkedFieldsCount(element.id) == 0"
                                                                        :data-bs-target='"#acc" + element.id'
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOne">
                                                                        <span style="color:#0469B1"><i
                                                                                class="fa-solid fa-plus"></i>Add
                                                                            fields</span>
                                                                    </button>
                                                                    <button class="btn btn-sm btn-light"
                                                                        style="min-width:100px;"
                                                                        v-on:click.prevent="selectTable(element.id)"
                                                                        data-bs-toggle="collapse"
                                                                        v-if="checkedFieldsCount(element.id) > 0"
                                                                        :data-bs-target='"#acc" + element.id'
                                                                        aria-expanded="true"
                                                                        aria-controls="collapseOne">
                                                                        <span style="color:#0469B1">Edit</span>
                                                                    </button>
                                                                </div>
                                                            </h5>
                                                        </div>
                                                        <div :id='"acc" + element.id' class="collapse"
                                                            aria-labelledby="headingOne" data-parent="#accordion">
                                                            <div class="card-body">
                                                                <div
                                                                    class="d-sm-flex align-items-center justify-content-between border p-2 rounded">
                                                                    <span class="m-0" style="font-size:smaller;">
                                                                        Selected fields {{
                                                                            checkedFieldsCount(element.id) }}
                                                                    </span>
                                                                    <span class="m-0" style="font-size:smaller;"
                                                                        v-if="allCheckedChecker(element.id)">
                                                                        <a href="#" class="text-success"
                                                                            v-on:click.prevent="checkAll(element.id)"><i
                                                                                class="fa-solid fa-circle-check pe-2"></i>
                                                                            Select
                                                                            all
                                                                        </a>
                                                                    </span>
                                                                    <span class="m-0" style="font-size:smaller;" v-else>
                                                                        <a href="#"
                                                                            v-on:click.prevent="checkAll(element.id)"><i
                                                                                class="fa-sharp fa-regular fa-circle pe-2"
                                                                                style="color:#d1d1d1"></i> Select all
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded">
                                                                    <div class="input-group">
                                                                        <input type="text" v-model="searchTerm"
                                                                            class="form-control bg-light border-0"
                                                                            placeholder="Search fields"
                                                                            aria-label="Search"
                                                                            aria-describedby="basic-addon2" />
                                                                    </div>
                                                                </div>
                                                                <div class="overflow-auto" style="height: 300px;">
                                                                    <a href="#" class="list-inline-item m-1 mb-2"
                                                                        style="border: 0;"
                                                                        v-for="field in filteredFields" :key="field.id"
                                                                        v-on:click.prevent="checkField(element.id, field.id, field.isChecked)">
                                                                        <div v-if="!field.isChecked"
                                                                            class="d-sm-flex align-items-center justify-content-between p-1">
                                                                            <i class="fa-sharp fa-regular fa-circle pe-2"
                                                                                style="color:#d1d1d1"></i>
                                                                            <span class="m-1 mb-1 fw-bold"
                                                                                style="color:#a1a1a1; min-width:100px;">
                                                                                {{ field.fieldname }}
                                                                            </span>
                                                                        </div>
                                                                        <div v-else
                                                                            class="d-sm-flex align-items-center justify-content-between p-1">
                                                                            <i
                                                                                class="fa-solid fa-circle-check pe-2 text-success"></i>
                                                                            <span class="m-1 mb-1 fw-bold text-success"
                                                                                style="min-width:100px;">
                                                                                {{ field.fieldname }}
                                                                            </span>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </draggable>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Filters and joins" style="min-height: 400px;">
                                <div id="accordionFinalized">
                                    <div class="row overflow-auto" style="height: 400px;">
                                        <div class="col-md-12">
                                            <div class="card" v-for="checkedTable in checkedTables"
                                                :key="checkedTable.id">
                                                <div class="card-header" :id='"headingOne" + checkedTable.tabname'>
                                                    <h5 class="mb-0">
                                                        <div
                                                            class="d-sm-flex align-items-center justify-content-between border m-2 p-2 rounded">
                                                            <span class="m-0 fw-bold">
                                                                {{ checkedTable.tabname }}
                                                            </span>
                                                            <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                                data-bs-toggle="collapse"
                                                                v-on:click.prevent="selectTable(checkedTable.id)"
                                                                v-if="checkedTable.id != selectedTableId"
                                                                :data-bs-target='"#acc" + checkedTable.id'
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                <span style="color:#0469B1"><i
                                                                        class="fa fa-duotone fa-chevron-down"></i></span>
                                                            </button>
                                                            <button class="btn btn-sm btn-light" style="min-width:10px;"
                                                                v-on:click.prevent="selectTable(checkedTable.id)"
                                                                data-bs-toggle="collapse"
                                                                v-if="checkedTable.id == selectedTableId"
                                                                :data-bs-target='"#acc" + checkedTable.id'
                                                                aria-expanded="true" aria-controls="collapseOne">
                                                                <span style="color:#0469B1"><i
                                                                        class="fa fa-duotone fa-chevron-up"></i></span>
                                                            </button>
                                                        </div>
                                                    </h5>
                                                </div>
                                                <div :id='"acc" + checkedTable.id' class="collapse"
                                                    aria-labelledby="headingOne" data-parent="#accordionFinalized">
                                                    <div class="card-body">
                                                        <h6 class="h6">Advanced filtering</h6>
                                                        <div
                                                            class="d-sm-flex align-items-center justify-content-between border p-2 mb-2 rounded">
                                                            <button class="btn btn-sm btn-outline-secondary" style="min-width:10px;"
                                                                data-bs-toggle="modal" data-bs-target="#filtersModal"
                                                                v-on:click.prevent="selectTable(checkedTable.id)">
                                                                <span>Value filter</span>
                                                            </button>
                                                            <button class="btn btn-sm btn-outline-success" style="min-width:10px;"
                                                                data-bs-toggle="modal" data-bs-target="#joinsModal"
                                                                v-on:click.prevent="selectTable(checkedTable.id)">
                                                                <span>Join fields</span>
                                                            </button>
                                                        </div>

                                                        <div
                                                            class="d-sm-flex align-items-center justify-content-between border p-2 mt-5 rounded">
                                                            <span class="m-0" style="font-size:smaller;">
                                                                Selected date fields {{
                                                                    checkedDateFieldsCount(checkedTable.id) }}
                                                            </span>
                                                            <span class="m-0" style="font-size:smaller;"
                                                                v-if="allDateCheckedChecker(checkedTable.id)">
                                                                <a href="#" class="text-success"
                                                                    v-on:click.prevent="checkDateAll(checkedTable.id)"><i
                                                                        class="fa-solid fa-circle-check pe-2"></i>
                                                                    Select
                                                                    all </a>
                                                            </span>
                                                            <span class="m-0" style="font-size:smaller;" v-else>
                                                                <a href="#"
                                                                    v-on:click.prevent="checkDateAll(checkedTable.id)"><i
                                                                        class="fa-sharp fa-regular fa-circle pe-2"
                                                                        style="color:#d1d1d1"></i> Select
                                                                    all </a>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="d-sm-flex align-items-center justify-content-between border m-4 p-2 rounded">
                                                            <div class="input-group">
                                                                <input type="text" v-model="searchDateFieldTerm"
                                                                    class="form-control bg-light border-0"
                                                                    placeholder="Search fields" aria-label="Search"
                                                                    aria-describedby="basic-addon2" />
                                                            </div>
                                                        </div>
                                                        <div class="overflow-auto" style="height: 300px;">
                                                            <a href="#" class="list-inline-item m-1 mb-2"
                                                                style="border: 0;" v-for="field in filteredDateFields"
                                                                :key="field.id"
                                                                v-on:click.prevent="checkDateField(checkedTable.id, field.id, field.checkedDate)">
                                                                <div v-if="!field.checkedDate"
                                                                    class="d-sm-flex align-items-center justify-content-between p-1">
                                                                    <i class="fa-sharp fa-regular fa-circle pe-2"
                                                                        style="color:#d1d1d1"></i>
                                                                    <span class="m-1 mb-1 fw-bold"
                                                                        style="color:#a1a1a1; min-width:100px;">
                                                                        {{ field.fieldname }}
                                                                    </span>
                                                                </div>
                                                                <div v-else
                                                                    class="d-sm-flex align-items-center justify-content-between p-1">
                                                                    <i
                                                                        class="fa-solid fa-circle-check pe-2 text-success"></i>
                                                                    <span class="m-1 mb-1 fw-bold text-success"
                                                                        style="min-width:100px;">
                                                                        {{ field.fieldname }}
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal fade" id="filtersModal" tabindex="-1" role="dialog"
                                    aria-labelledby="filtersModal" aria-hidden="true">
                                    <div class="modal-dialog modal-lg" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Value filter</h5>
                                                <button class="btn-close" type="button" data-bs-dismiss="modal"
                                                    aria-label="Close">

                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="wheres.length > 0">
                                                    <div class="row" v-for="(where, index) in selectedWheres"
                                                        :key="index">
                                                        <div class="mb-3 col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.field">
                                                        </div>
                                                        <div class="mb-3 col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.action">
                                                        </div>
                                                        <div class="mb-3 col-md-3">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.value">
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="where.where.operator">
                                                        </div>
                                                        <div class="mb-3 col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click.prevent="deleteWhere(index)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form class="row g-3">
                                                    <div class="form-floating mb-3 col-md-3">
                                                        <select id="field" class="form-select" v-model="where.field"
                                                            placeholder="Field">
                                                            <option v-for="field in filteredFields" :key="field.id"
                                                                :value="field.fieldname"> {{
                                                                    field.fieldname }}</option>
                                                        </select>
                                                        <label for="field">Field</label>
                                                    </div>
                                                    <div class="form-floating mb-3 col-md-3">
                                                        <select id="action_where" class="form-select"
                                                            v-model="where.action">
                                                            <option value="EQUAL">Equal</option>
                                                            <option value="NOT_EQUAL">Not Equal</option>
                                                            <option value="GREATER_THAN">Greater than</option>
                                                            <option value="GREATER_OR_EQUAL_THAN">Greater or Equal than
                                                            </option>
                                                            <option value="LOWER_THAN">Lower than</option>
                                                            <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                            </option>
                                                            <option value="CONTAINS">Contains</option>
                                                        </select>
                                                        <label for="action_where">Action</label>
                                                    </div>
                                                    <div class="form-floating mb-3 col-md-3">
                                                        <input type="text" class="form-control" id="whereValue"
                                                            v-model="where.value" placeholder="Value">
                                                        <label for="whereValue">Where</label>
                                                    </div>
                                                    <div class="form-floating mb-3 col-md-3">
                                                        <select id="operator" class="form-select"
                                                            v-model="where.operator">
                                                            <option value="AND">AND</option>
                                                            <option value="OR">OR</option>
                                                        </select>
                                                        <label for="operator">Operator</label>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click.prevent="saveWhereClause()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-bs-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal fade" id="joinsModal" tabindex="-1" role="dialog"
                                    aria-labelledby="joinsModal" aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Join fields</h5>
                                                <button class="btn-close" type="button" data-bs-dismiss="modal"
                                                    aria-label="Close">

                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div v-if="joins.length > 0">
                                                    <div class="row" v-for="(join, index) in selectedJoins"
                                                        :key="index">
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldSource">
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.action">
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.tableDestination">
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.fieldDestination">
                                                        </div>
                                                        <div class="mb-3 col-md-2">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.operator">
                                                        </div>
                                                        <div class="mb-3 col-md-1">
                                                            <input type="text" class="form-control disabled" disabled
                                                                :value="join.join.group">
                                                        </div>
                                                        <div class="mb-3 col-md-1">
                                                            <button type="submit" class="btn btn-outline-danger"
                                                                v-on:click.prevent="deleteJoin(index)"><i
                                                                    class="fa-solid fa-trash-can"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form class="row g-3">
                                                        <div class="form-floating mb-3 col-md-2">
                                                            <select id="fieldS" class="form-select"
                                                                v-model="join.fieldSource">
                                                                <option v-for="field in filteredFields" :key="field.id">
                                                                    {{
                                                                        field.fieldname }}</option>
                                                            </select>
                                                            <label for="fieldS">Field</label>
                                                        </div>
                                                        <div class="form-floating mb-3 col-md-2">
                                                            <select id="action" class="form-select"
                                                                v-model="join.action">
                                                                <option value="EQUAL">Equal</option>
                                                                <option value="NOT_EQUAL">Not Equal</option>
                                                                <option value="GREATER_THAN">Greater than</option>
                                                                <option value="GREATER_OR_EQUAL_THAN">Greater or Equal
                                                                    than
                                                                </option>
                                                                <option value="LOWER_THAN">Lower than</option>
                                                                <option value="LOWER_OR_EQUAL_THAN">Lower or Equal than
                                                                </option>
                                                                <option value="CONTAINS">Contains</option>
                                                            </select>
                                                            <label for="action">Action</label>
                                                        </div>
                                                        <div class="form-floating mb-3 col-md-2">
                                                            <select id="fieldD" class="form-select"
                                                                v-on:change="changeTableFields($event)"
                                                                v-model="join.tableId">
                                                                <option v-for="table in tables" :key="table.id"
                                                                    :value="table.id">{{ table.tabname
                                                                    }}</option>
                                                            </select>
                                                            <label for="fieldD">Destination table</label>
                                                        </div>
                                                        <div class="form-floating mb-3 col-md-2">
                                                            <select id="table" class="form-select"
                                                                v-model="join.fieldDestination">
                                                                <option v-for="field in joinTableFields" :key="field.id"
                                                                    :value="field.fieldname">{{
                                                                        field.fieldname }}</option>
                                                            </select>
                                                            <label for="table">Destination field</label>
                                                        </div>
                                                        <div class="form-floating mb-3 col-md-2">
                                                            <select id="operator_join" class="form-select"
                                                                v-model="join.operator">
                                                                <option value="AND">AND</option>
                                                                <option value="OR">OR</option>
                                                            </select>
                                                            <label for="operator_join">Operator</label>
                                                        </div>
                                                        <div class="form-floating mb-3 col-md-1">
                                                            <select id="group" class="form-select" v-model="join.group">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                            </select>
                                                            <label for="group">Group</label>
                                                        </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer">
                                                <a class="btn btn-primary" v-on:click.prevent="saveJoin()">
                                                    <i class="fa-solid fa-plus" style="min-width:20px;"></i>Add
                                                </a>
                                                <a class="btn btn-success" data-bs-dismiss="modal">Finish</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Schedule" style="min-height: 400px;">

                                <div class="row overflow-auto" style="min-height:800px">
                                    <div class="col-md-12 col-sm-12">
                                        <form>
                                            <div class="row">
                                                <div class="mb-3 col-md-12">
                                                    <span data-bs-toggle="tooltip" class="text-info float-end" disabled
                                                        data-bs-placement="auto" title="Choose frequency and occurrence that starts from Start date, eg:
    1-Day - runs every day
    3-Day runs every 3 days
    1-Week runs once a week
    3-Week runs every 3 weeks
    1-Month runs every month
    3-Month runs every 3 months
    Once - will run once on selected date">
                                                        <i class="fa-regular fa-circle-question"></i>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="mb-3 col-md-6">
                                                    <h6 class="h5">Project filter dates </h6>
                                                    <div style="min-width:100px;">From:</div>
                                                    <datepicker v-model="project.dateStart"></datepicker>
                                                    <div style="min-width:100px;">To:</div>
                                                    <datepicker v-model="project.dateEnd"></datepicker>
                                                </div>
                                                <div class="mb-3 col-md-6 form-check">
                                                    <input class="form-check-input" type="checkbox" id="dateOverride"
                                                        v-model="overrideDates" @change="dateOverrideChange($event)">
                                                    <label class="form-check-label h5" style="font-weight: normal;"
                                                        for="dateOverride"> Custom date
                                                        filter
                                                    </label>

                                                    <div v-if="overrideDates">

                                                        <div style="min-width:100px;">From:</div>
                                                        <datepicker v-model="job.calendar.dateFrom"
                                                            :class="{ 'border border-danger': dateFromError }">
                                                        </datepicker>
                                                        <div style="min-width:100px;">To:</div>
                                                        <datepicker v-model="job.calendar.dateTo"
                                                            :class="{ 'border border-danger': dateToError }">
                                                        </datepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" style="border-top: 1px solid #d1d3e2;">
                                                <div class="mb-3 col-md-6 mt-4">
                                                    <div style="min-width:100px;">Start:</div>
                                                    <datepicker :class="{ 'border border-danger': dateStartError }"
                                                        v-model="job.calendar.dateStart"
                                                        :disabled-dates="disabledDates">
                                                    </datepicker>
                                                </div>
                                                <div class="mb-3 col-md-6 mt-4" v-if="!overrideDates">
                                                    <div style="min-width:100px;">End:</div>
                                                    <datepicker v-model="job.calendar.endDate"
                                                        :disabled-dates="disabledDates"></datepicker>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="mb-3 col-md-6">
                                                    <div style="min-width:100px;">New time:</div>
                                                    <VueTimePicker :format="timeFormat" :minute-interval="15"
                                                        style="max-width:200px;" v-model="timeData">
                                                    </VueTimePicker>
                                                </div>
                                            </div>
                                            <div class="row g-3">
                                                <div class="form-floating mb-3 col-md-6">
                                                    <select id="occurrence" class="form-select"
                                                        :disabled="overrideDates" v-model="job.calendar.occurrence"
                                                        placeholder="Occurrence" style="max-width: 200px;">
                                                        <option value="NONE">None</option>
                                                        <option value="DAY">Day</option>
                                                        <option value="WEEK">Week</option>
                                                        <option value="MONTH">Month</option>
                                                        <option value="ONCE">Once</option>
                                                    </select>
                                                    <label for="occurrence" style="opacity: 1;font-weight: 600;">Occurrence</label>
                                                </div>
                                                <div class="form-floating mb-3 col-md-6">
                                                    <input type="text" class="form-control" v-model="job.calendar.step"
                                                        :disabled="overrideDates"
                                                        :class="{ 'border border-danger': stepError }"
                                                        placeholder="Frequency" aria-label="Update" id="frequency"
                                                        aria-describedby="basic-addon2" style="max-width:200px;" />
                                                    <label for="frequency"  style="opacity: 1;font-weight: 600;">Frequency: </label>
                                                </div>
                                            </div>
                                            <div class="row g-3">
                                                <p>Choose default period to extract data from:</p>
                                                <div class="form-floating mb-3 col-md-12">
                                                    <select id="occurrence-predefined" class="form-select"
                                                        :disabled="overrideDates" v-model="job.calendar.period"
                                                        placeholder="Period" style="max-width: 200px;">
                                                        <option value="NONE">None</option>
                                                        <option value="PREVIOUS_DAY">Previous day</option>
                                                        <option value="CURRENT_DAY">Current day</option>
                                                        <option value="PREVIOUS_WEEK">Previous week</option>
                                                        <option value="CURRENT_WEEK">Current week</option>
                                                        <option value="PREVIOUS_MONTH">Previous month</option>
                                                        <option value="CURRENT_MONTH">Current month</option>
                                                        <option value="PREVIOUS_QUARTER">Previous quarter</option>
                                                        <option value="CURRENT_QUARTER">Current quarter</option>
                                                        <option value="PREVIOUS_HALF_YEAR">Previous half year</option>
                                                        <option value="CURRENT_HALF_YEAR">Current half year</option>
                                                        <option value="PREVIOUS_YEAR">Previous year</option>
                                                        <option value="CURRENT_YEAR">Current year</option>
                                                    </select>
                                                    <label for="occurrence-predefined"  style="opacity: 1;font-weight: 600;">Period: </label>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="modal fade" id="infoScheduleModal" tabindex="-1" role="dialog"
                                    aria-labelledby="infoScheduleModal" aria-hidden="true">
                                    <div class="modal-dialog modal-xl" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Examples</h5>
                                                <button class="btn-close" type="button" data-bs-dismiss="modal"
                                                    aria-label="Close">

                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="input-group col-md-12">
                                                    <p>Choose frequency and occurrence that starts from
                                                        Start date,
                                                        eg:
                                                    </p>
                                                </div>
                                                <div class="input-group col-md-12">
                                                    <ul>
                                                        <li>1-Day - runs every day</li>
                                                        <li>3-Day runs every 3 days</li>
                                                        <li>1-Week runs once a week</li>
                                                        <li>3-Week runs every 3 weeks</li>
                                                        <li>1-Month runs every month</li>
                                                        <li>3-Month runs every 3 months</li>
                                                        <li>Once - will run once on selected date</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                        </form-wizard>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import ProjectsService, { ApplicationError } from "../services/projectsService";
import LoadingIndicator from "../components/LoadingIndicator.vue";
import { useRoute } from 'vue-router'
import ConnectionsService from "../services/connectionsService";
import { FormWizard, TabContent } from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import draggable from 'vuedraggable';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ConnectionComponent from "@/components/ConnectionComponent.vue";
import Datepicker from 'vuejs3-datepicker';
import VueTimePicker from "vue3-timepicker";
import "vue3-timepicker/dist/VueTimepicker.css";

export default {
    name: "ProjectJobsView",
    data: () => {
        return {
            currentTabIndex: 0,
            project: {
                id: null,
                createdBy: {},
                tenant: {},
                jobs: []
            },
            loading: true,
            searchDateFieldTerm: null,
            tables: [],
            fields: [],
            filteredFields: [],
            filteredDateFields: [],
            allChecked: false,
            allDateChecked: false,
            searchTerm: null,
            selectedTableId: null,
            job: {
                name: null,
                tasks: [],
                calendar: {
                    step: 0,
                    occurrence: "NONE",
                    dateStart: null,
                    period: "NONE"
                }
            },
            wheres: [],
            joins: [],
            joinTableFields: [],
            where: {
                field: null,
                action: null,
                value: null,
                operator: null,
            },
            join: {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: null,
                operator: null,
                tableId: null,
                tableDestination: null,
                group: null
            },
            checkedTables: [],
            newTable: null,
            stepError: false,
            dateStartError: false,
            dateFromError: false,
            dateToError: false,
            timeFormat: "HH:mm",
            timeData: {
                HH: "09",
                mm: "00"
            },
            time: null,
            disabledDates: {
                to: new Date(new Date().setDate(new Date().getDate() - 1))
            },
            overrideDates: false
        };
    },
    components: {
        LoadingIndicator,
        FormWizard,
        TabContent,
        draggable,
        ConnectionComponent,
        Datepicker,
        VueTimePicker
    },
    computed: {
        selectedWheres() {
            return this.wheres.filter(x => x.selectedTableId == this.selectedTableId);
        },
        selectedJoins() {
            return this.joins.filter(x => x.selectedTableId == this.selectedTableId);
        }
    },
    watch: {
        searchTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchTerm?.toLowerCase()) > -1);
            } else {
                this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        },
        searchDateFieldTerm(newTerm, oldTerm) {
            if (newTerm != oldTerm) {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId)
                    .fields.filter(x => x.fieldname.toLowerCase().indexOf(this?.searchDateFieldTerm?.toLowerCase()) > -1);
            } else {
                this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields;
            }
        }
    },
    created() {
        const route = useRoute();
        this.projectId = route.params.id;

        this.getProject();
    },
    methods: {
        selectTable(tableId) {
            this.selectedTableId = tableId;
            this.filteredFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.isChecked) ? -1 : 1).sort((a, b) => (a.fieldname < b.fieldname && a.isChecked) ? -1 : 1);
            this.filteredDateFields = this.tables.find(x => x.id == this.selectedTableId).fields.sort((a) => (a.checkedDate) ? -1 : 1).sort((a, b) => (a.fieldname < b.fieldname && a.checkedDate) ? -1 : 1);
            this.searchTerm = null;
            this.searchDateFieldTerm = null;
        },
        allCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId)?.fields?.filter(x => !x.isChecked).length > 0;
        },
        allDateCheckedChecker(tableId) {
            return !this.tables.find(x => x.id == tableId)?.fields?.filter(x => !x.checkedDate).length > 0;
        },
        checkedFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId)?.fields?.filter(x => x.isChecked)?.length ?? 0;
        },
        checkedDateFieldsCount(tableId) {
            return this.tables.find(x => x.id == tableId)?.fields?.filter(x => x.checkedDate)?.length ?? 0;
        },
        checkTable(id, isChecked) {
            this.tables.find(x => x.id == id).isChecked = !isChecked;
            this.checkedTables = this.tables.filter(x => x.isChecked);
        },
        checkField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).isChecked = !isChecked;
        },
        checkDateField(tableId, fieldId, isChecked) {
            this.tables.find(x => x.id == tableId).fields.find(x => x.id == fieldId).checkedDate = !isChecked;
        },
        checkAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.isChecked = !this.allChecked; })
            this.allChecked = !this.allChecked;
        },
        checkDateAll(tableId) {
            this.tables.find(x => x.id == tableId).fields.forEach((item) => { item.checkedDate = !this.allDateChecked; })
            this.allDateChecked = !this.allDateChecked;
        },
        validateConnection() {
            if (!this.project.connectionConfigurationId) {
                return false;
            }

            return true;
        },
        getProject() {
            ProjectsService.getProject(this.projectId).then(
                (project) => {
                    this.project = project;
                    this.getTables();
                });
        },
        getTables() {
            this.loading = true;
            this.tables = [];

            ProjectsService.getTables(this.project.id, this.project.connectionConfigurationId).then(
                (tables) => {
                    tables.forEach((item) => {

                        item.isChecked = this.checkedTables.find(x => x.id == item.id)?.isChecked ?? false;

                        item.fields.forEach((f) => {
                            f.isChecked = false;
                            f.checkedDate = false;
                        })

                        this.tables.push(item);
                    })

                    this.loading = false;
                });
        },
        changeTableFields(event) {
            this.joinTableFields = this.tables.find(x => x.id == event.target.value).fields;
        },
        saveWhereClause() {
            let where = {
                selectedTableId: this.selectedTableId,
                where: this.where
            };

            this.wheres.push(where);
            this.where = {
                field: null,
                action: null,
                value: null,
                operator: null,
            };
        },
        saveJoin() {

            let tableSelected = this.tables.find(x => x.id == this.join.tableId);

            let join = {
                selectedTableId: this.selectedTableId,
                join: this.join
            };

            join.join.tableDestination = tableSelected.tabname;

            this.joins.push(join);
            this.join = {
                id: null,
                fieldSource: null,
                fieldDestination: null,
                action: null,
                operator: null,
                tableId: null,
                tableDestination: null
            };
        },
        deleteWhere(index) {
            this.wheres.splice(index, 1);
        },
        deleteJoin(index) {
            this.joins.splice(index, 1)
        },
        checkAllFieldsCount() {
            let invalidFields = false;

            this.checkedTables.forEach((i) => {

                if (!this.checkedFieldsCount(i.id)) {

                    invalidFields = true;
                }
            });

            if (invalidFields) {

                this.loading = false;

                throw new ApplicationError(1, 'Application error: select at least one field for each table!');
            }
        },
        finalize() {

            const $toast = useToast();

            this.loading = true;
            this.stepError = false;
            this.dateStartError = false;
            this.dateToError = false;
            this.dateFromError = false;

            if (this.job.name == null) {
                const $toast = useToast();
                $toast.error('Application error: You need to specify project name!');
                this.loading = false;
                return;
            }

            if (this.checkedTables.length == 0) {

                $toast.error('Application error: select at least one table!');
                this.loading = false;
                return;
            }

            try {
                this.checkAllFieldsCount();
            } catch (error) {
                $toast.error(error.message);
                return;
            }

            this.job.tasks = [];

            this.checkedTables.forEach((i, index) => {

                var checkedFields = [];
                var checkedDates = [];

                i.fields.forEach(f => {
                    if (f.isChecked) {
                        checkedFields.push(f.fieldname);
                    }

                    if (f.checkedDate) {
                        checkedDates.push(f.fieldname);
                    }
                })

                let task = {
                    name: i.tabname,
                    dateFields: checkedDates,
                    fields: checkedFields,
                    whereClauses: this.getWheres(this.wheres.filter(x => x.selectedTableId == i.id)),
                    joinClauses: this.getJoins(this.joins.filter(x => x.selectedTableId == i.id)),
                    order: index
                }

                this.job.tasks.push(task);
            });

            if (this.job.calendar.occurrence != "NONE" && this.job.calendar.occurrence != "ONCE") {
                if (this.job.calendar.step == 0) {
                    this.stepError = true;

                    $toast.error('You need to define value for frequency');
                    this.loading = false;
                    return;
                }
            }

            if (!this.job.calendar.dateStart) {
                this.dateStartError = true;

                $toast.error('You need to define start date for occurrence');
                this.loading = false;
                return;
            }

            if (this.overrideDates && !this.job.calendar.dateFrom) {

                $toast.error('You need to define custom date FROM filter');
                this.loading = false;
                return;
            }

            if (this.overrideDates && !this.job.calendar.dateTo) {
                this.dateToError = true;

                $toast.error('You need to define custom date TO filter');
                this.loading = false;
                return;
            }

            this.job.calendar.hours = this.timeData.HH;
            this.job.calendar.minutes = this.timeData.mm;
            this.loading = true;
            ProjectsService.addJob(this.projectId, this.job).then(
                () => {
                    this.$router.push({ name: 'ProjectDetails', params: { id: this.projectId } });
                })
                .catch(error => {
                    this.loading = false;
                    console.log(error.message)
                    const $toast = useToast();
                    $toast.error('There was an error, please try again later or contact support');
                })
        },
        getWheres(selectedWheres) {
            let wheres = []

            selectedWheres.forEach((w) => wheres.push(w.where));

            return wheres;
        },
        getJoins(selectedJoins) {
            let joins = []

            selectedJoins.forEach((j) => joins.push(j.join));

            return joins;
        },
        async addNewTable() {

            if (!this.newTable) {
                const $toast = useToast();
                $toast.error('Application error: You need to specify table name!');
                return;
            }

            try {
                this.loading = true;
                await ConnectionsService.extractSpecificTable(this.project.connectionConfigurationId, this.project.id, null, this.newTable).then(
                    (e) => {
                        console.log(e)
                        this.getTables();
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error.message)
                        const $toast = useToast();
                        $toast.error('There was an error, please try again later or contact support');
                    })
            }
            catch (error) {
                const $toast = useToast();
                $toast.error(error);
                this.loading = false;
                return;
            }

        },
        dateOverrideChange() {
            if (this.overrideDates) {
                this.job.calendar.occurrence = "ONCE"
            }
            else {
                this.job.calendar.occurrence = "NONE"
            }
        },
        async removeTable(tableName) {
            try {
                this.loading = true;
                await ConnectionsService.deleteSpecificTable(this.project.id, this.jobId, tableName).then(
                    (e) => {
                        console.log(e)
                        this.getTables();
                    })
                    .catch(error => {
                        this.loading = false;
                        console.log(error.message)
                        const $toast = useToast();
                        $toast.error('There was an error, please try again later or contact support');
                    })
            }
            catch (error) {
                const $toast = useToast();
                $toast.error(error);
                this.loading = false;
                return;
            }
        }
    }
};
</script>

<style>
.list-inline-item {
    border: 1px #d1d1d1 solid;
}

.time-preview {
    border: 1px solid #eee;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 15px;
    background: rgb(70, 25, 173);
    color: #fff;
}

.time-preview-time {
    font-size: 30px;
}

.vue-form-wizard .wizard-card-footer {
    padding: 0 10px;
    padding-top: 20px;
}

[v-cloak] {
    display: none;
}

.vue__time-picker input.vue__time-picker-input {
    width: 200px;
}

.active-table {
    background-color: #cdf4ff;
    color: #4e73df;
}

.inactive-table {
    background-color: #FFF;
    color: #4e73df;
    border: 1px solid #04b187;
}
.form-floating>.form-control-plaintext~label::after, .form-floating>.form-control:focus~label::after, .form-floating>.form-control:not(:placeholder-shown)~label::after, .form-floating>.form-select~label::after {
    background: transparent;
}
</style>
